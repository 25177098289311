.welcome15-overlay #cboxLoadedContent {
  .welcome-offer-popup-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__button {
      width: 200px;
      border: none;
      border-radius: 0;
      margin: 20px 10px;
      padding: 10px;
      &:hover > a {
        color: $color-dark-gray;
      }
    }
  }
}
