.site-content {
  &.sign-in-page {
    .new-account {
      .address_form_container {
        label {
          display: none;
        }
        .ship_type_container {
          label {
            display: inline-block;
          }
        }
      }
      &__item {
        &.password2 {
          label {
            display: none;
          }
        }
      }
    }
  }
  .signup-tout {
    &-content {
      .headline {
        font-size: 36px !important;
        @include breakpoint($medium-up) {
          font-size: 40px !important;
        }
      }
      .subtitle {
        font-size: 24px;
        @include breakpoint($medium-up) {
          font-size: 30px;
        }
      }
    }
  }
  .product-full {
    &__desc,
    &__shadename {
      font-size: 20px;
    }
    &.product-full-v1 {
      .product-full__shadename {
        font-size: 13px;
      }
      .product-full__sku-selector-container {
        width: 108%;
      }
      .product-full__cta {
        .button {
          font-size: 20px;
          height: 65px;
          line-height: 1;
        }
      }
      .product-full__mobile-panel {
        .product-full__accordion__panel {
          margin-bottom: 16px;
        }
      }
    }
  }
  .text--1 {
    font-size: 18px;
    @include breakpoint($medium-up) {
      font-size: 23px;
      line-height: 1;
    }
  }
  .offer-banner-item {
    &__text {
      .link {
        font-size: 18px;
        display: inline;
        @include breakpoint($medium-up) {
          font-size: 23px;
        }
      }
    }
  }
  .content-block-large {
    &__content-button {
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 20px;
        line-height: 0.5;
      }
    }
  }
  .product__button {
    &--add-to-bag {
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 20px;
        line-height: 0.5;
      }
    }
  }
  .mpp-tout {
    &__body {
      font-size: 22px;
      line-height: 1;
      font-family: $font--dbheavent;
      margin-top: 0;
      @include breakpoint($medium-up) {
        line-height: 1;
        width: 87%;
      }
    }
  }
  .product-subline {
    font-size: 21px;
    @include breakpoint($medium-up) {
      font-size: 23px;
    }
  }
  .product-grid {
    &--medium-cols-4 {
      .product-subline {
        a {
          font-size: 21px;
          @include breakpoint($medium-up) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.viewcart {
  .checkout {
    &__sidebar {
      .links-content {
        a:first-child {
          text-decoration: none;
        }
      }
      .checkout-panel {
        .order-summary {
          &-content {
            &__total {
              display: inline-block;
              margin-top: 1em;
              padding-top: 0.5em;
              width: 100%;
              border-top: 1px solid $color-light-gray;
              border-bottom: 1px solid $color-light-gray;
              @include breakpoint($medium-up) {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
  .go-shopping {
    &--btn {
      font-size: 20px;
    }
  }
  .single-message {
    font-size: 20px;
  }
  .checkout-panel {
    &--guarantee {
      .checkout-panel {
        &__heading {
          font-size: 30px;
        }
      }
    }
  }
  .recommended-products-panel {
    .checkout-panel {
      &__heading {
        font-size: 30px;
      }
    }
  }
}

.address-form {
  &__item {
    width: 50%;
    float: left;
  }
  &__phone-2-container {
    width: 100%;
  }
  &__header {
    text-align: center;
  }
}

.error_messages,
.error,
.cod-message {
  color: $color-red;
  font-size: 18px;
}

.shipping {
  &.checkout {
    .create-password {
      .note {
        font-size: 12px;
      }
    }
    .address-container {
      font-style: normal;
      font-size: 18px;
      p {
        font-size: 18px;
      }
      img {
        margin-left: 10px;
      }
    }
  }
}

.review {
  &-panel {
    h3,
    h4 {
      margin-top: 25px;
    }
    h4 {
      margin-bottom: 15px;
    }
    .trans_detail_item {
      border-top: 1px solid $color-light-gray;
      margin-top: 15px;
      .edit {
        margin-right: 15px;
        font-size: 20px;
        padding: 10px;
      }
      address {
        font-style: normal;
      }
      &.gift,
      &.payment_method,
      &.ship_method {
        &_container {
          font-size: 18px;
        }
      }
    }
    .checkout__panel-content {
      .cart-item {
        &.product {
          padding: 25px 0;
          border-top: 1px solid $color-light-gray;
        }
        &__thumb {
          width: 15%;
          height: 160px;
          float: left;
          margin-right: 2%;
        }
      }
      .cart-items__item {
        &--group-desc-remove {
          float: left;
          width: 30%;
        }
        &--price {
          float: left;
          margin-right: 60px;
          font-size: 20px;
        }
        &--qty {
          width: 80px;
          float: left;
          a.qty_select {
            min-width: 80px;
          }
        }
        &--total {
          float: right;
          font-weight: bold;
        }
        &--desc {
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.product-social {
  &__item--facebook {
    .icon {
      font-size: 22px;
      &--facebook {
        &:before {
          font-size: 20px;
        }
      }
    }
  }
}

a {
  &.selectBox {
    font-size: 16px;
    font-family: $font--dbheavent;
  }
}

.confirmation {
  .confirmation-panel {
    &__email-coming {
      font-size: 20px;
    }
  }
  .print-buttons {
    .button {
      font-size: 20px;
    }
  }
  .customer-service-content {
    p {
      font-size: 20px;
    }
  }
}

.checkout {
  &__panel {
    &__heading {
      font-size: 30px;
    }
  }
}

.account-section {
  .sidebar-menu {
    a {
      font-size: 18px;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
  }
  &__welcome {
    font-size: 20px;
    .link {
      &--secondary {
        font-size: 18px;
      }
    }
  }
  .tabbed-block {
    &__tab {
      font-size: 16px;
    }
  }
  .no-orders {
    &__text,
    &__link {
      font-size: 14px;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
    &__link {
      line-height: 0.5;
    }
  }
  .orders-list {
    &__table {
      &--order-number,
      &--order-total,
      &--order-date {
        font-size: 20px;
        @include breakpoint($medium-up) {
          font-size: 18px;
        }
      }
    }
  }
}

.profile-page {
  .profile-info,
  .optional-info {
    &__header {
      font-size: 20px;
    }
    &__item {
      &.gender,
      &.skin_type,
      &.skin_concern {
        label,
        span {
          font-size: 20px;
        }
      }
      &.age {
        .label-content,
        .selectBox-label {
          font-size: 20px;
        }
      }
      &--title {
        font-size: 20px;
      }
    }
  }
  .newsletter-info {
    &__fieldset {
      font-size: 20px;
    }
    .form-submit {
      font-family: $font--dbheavent;
      font-size: 14px;
    }
  }
  .optional-info {
    &__fieldset {
      label {
        &:first-child {
          font-size: 20px;
        }
      }
      .selectBox-arrow {
        right: 20px;
      }
    }
  }
}

.section-trustmark {
  .content-block {
    &-tout {
      &__text-inner {
        .content-block-tout {
          &__text {
            &-headline {
              font-size: 48px;
            }
            &-body {
              font-size: 20px;
              line-height: 1;
            }
          }
        }
      }
    }
    &-collapsible {
      &__content-body {
        .heading {
          font-family: $font--dbheavent;
          font-size: 23px;
        }
      }
    }
  }
}

.checkout-index,
.checkout-samples,
.checkout-viewcart,
.signin,
.checkout-shipping,
.checkout-review {
  .site-footer-pc,
  .site-footer__bottom,
  .menu-ref,
  .social-media-links,
  .basic-noderef {
    display: none;
  }
  .site-footer {
    &__bottom-condensed {
      display: block;
    }
  }
}

#pr-rating-tab {
  .content-block-collapsible {
    &__headline {
      font-size: 50px;
    }
  }
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-snapshot {
        &-block-container {
          .pr-review-snapshot {
            &-block-histogram {
              .pr-histogram-label {
                font-size: 16px;
              }
            }
          }
          .pr-snippet {
            &-read-and-write {
              .pr-snippet {
                &-write-review-link {
                  font-size: 20px !important;
                  line-height: 0.8;
                }
              }
            }
          }
        }
      }
      .pr-multiselect {
        span {
          font-size: 16px;
        }
      }
      .pr-review-display {
        .pr-rd-main-header {
          .pr-rd-main-header {
            &-search-sort {
              .pr-rd-review {
                &-header-sorts {
                  .pr-rd-sort {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
      .pr-rd {
        &-content-block {
          span {
            font-size: 23px;
          }
          .pr-rd {
            &-review-position {
              span {
                font-size: 23px;
              }
            }
            &-to-top {
              font-size: 23px;
            }
          }
        }
      }
      .pr-rd-description-text {
        font-size: 22px;
      }
    }
  }
}

.section-offers {
  .content-block-tout {
    &__text-body {
      font-size: 23px;
      font-family: $font--dbheavent;
    }
    &__text-buttons {
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 20px;
        line-height: 0.5;
      }
    }
  }
}

.site-header {
  .cart-block {
    &__title {
      font-size: 20px;
      line-height: 1;
    }
    &__content {
      .link {
        font-size: 20px;
        line-height: 1;
        top: 24px;
        @include breakpoint($medium-up) {
          top: 20px;
        }
      }
    }
  }
}

.regimen-category {
  &__add-to-bag {
    .button {
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
  }
}

.section-stores {
  .store-locator {
    &__form-column {
      .spa,
      .store {
        display: none;
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-size: 15px !important;
    color: $color-dark-gray !important;
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFlat {
      #onetrust-policy-text {
        font-size: 1.2em;
      }
      #onetrust-button-group {
        font-size: 1.3em;
      }
    }
  }
}
