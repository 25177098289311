#viewcart {
  .site-content {
    &.viewcart {
      .checkout-panel {
        &--viewcart {
          padding-bottom: 22px;
        }
        &--guarantee {
          padding: 14px 0 0 0;
        }
        &--bottom-viewcart-buttons {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
          @include breakpoint($landscape-up) {
            border-bottom: 1px solid $color-light-gray;
            padding-bottom: 40px;
            margin-bottom: 20px;
          }
        }
        &--samples {
          .checkout-panel__heading {
            display: block;
            cursor: pointer;
            .toggle-icon {
              float: #{$rdirection};
              margin-top: 5px;
            }
            .icon--plus {
              &.icon--minus {
                @include icon('minus');
                margin-top: 0px;
              }
            }
          }
        }
      }
      #recommended-products-panel {
        @include breakpoint($medium-up) {
          display: none;
        }
        @include breakpoint($landscape-up) {
          display: block;
        }
        padding-top: 14px;
        .recommended {
          &-item {
            padding: 0 3%;
            border-right: 1px solid $color-gray-light-apt;
            text-align: center;
            &:last-child {
              border: none;
            }
            @include breakpoint($landscape-up) {
              padding: 0 2%;
            }
            a {
              float: none;
              text-decoration: none;
            }
            .description {
              text-align: center;
              float: none;
              width: auto;
              .formatted__Price {
                margin-top: 8px;
                display: inline-block;
              }
            }
            .addtobag {
              margin-top: 10px;
              input[type='submit'] {
                margin: 0;
                min-width: 110px;
                @include breakpoint($landscape-up) {
                  margin: 0 30px;
                }
              }
            }
          }
        }
      }
      .checkout {
        &__content {
          .cart-items {
            &__item {
              &--price {
                @include breakpoint($landscape-up) {
                  width: 23%;
                }
              }
              &--total {
                @include breakpoint($landscape-up) {
                  width: 15%;
                }
              }
            }
            &__header {
              &--price {
                @include breakpoint($landscape-up) {
                  width: 23%;
                }
              }
            }
          }
        }
        &__sidebar {
          .checkout-panel {
            margin: 0;
            @include breakpoint($landscape-up) {
              margin: 0 1em;
            }
            &--order-summary {
              .label {
                width: 55%;
                @include breakpoint($landscape-up) {
                  width: 65%;
                }
              }
              .value {
                width: 45%;
                @include breakpoint($landscape-up) {
                  width: 35%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.site-footer {
  &__bottom {
    @include breakpoint($medium-up) {
      margin-bottom: 75px;
    }
    @include breakpoint($landscape-up) {
      margin-bottom: 0;
      font-size: 18px;
    }
  }
  &__copyright {
    font-size: 11px;
  }
}

.signin {
  .checkout {
    &__index-content {
      .checkout__content {
        margin: 0 auto;
        float: none;
      }
      .signin-panel__title {
        text-align: center;
        font-family: $font--neue-haas-unica-pro;
        font-size: 34px;
        text-transform: uppercase;
      }
      .new-account,
      .return-user {
        &__submit {
          width: 100%;
          font-family: $font--dbheavent;
          font-size: 20px;
          line-height: 0.5;
        }
      }
    }
    .error {
      &_messages {
        text-align: center;
      }
    }
  }
}

.shipping {
  .checkout {
    &__content {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 66.66%;
      }
      @include breakpoint($medium-landscape-only) {
        width: 59.33%;
      }
      .shipping-page-info-panel {
        .delivery_opt {
          .form-item {
            margin: 0 20px 10px 0;
            font-size: 16px;
          }
        }
        .form {
          &_element {
            #selectedStoreDetails {
              margin-bottom: 0;
            }
            a {
              margin-bottom: 1em;
              display: inline-block;
            }
          }
        }
        .address_controls {
          li {
            margin: 10px 0;
            font-size: 18px;
            .selectBox,
            select {
              width: 90%;
              margin-top: 1em;
              height: 47px;
              font-size: 18px;
            }
          }
        }
        .ship_type_container {
          clear: both;
          label {
            display: inline-block;
          }
        }
        .shipform_fields {
          .selectBox {
            width: 90%;
            margin: 10px 0;
          }
        }
        .address-container {
          .selectBox {
            height: 47px;
          }
        }
        .gift {
          &_options {
            margin-bottom: 20px;
            label {
              font-size: 20px;
            }
          }
          &-message {
            margin-top: 10px;
            textarea {
              width: 90%;
            }
          }
        }
        .payment-panel {
          font-size: 18px;
          .label-content,
          .form-item {
            display: block;
            margin-bottom: 5px;
          }
          .form-item {
            .installment {
              .label-content {
                display: inline;
              }
            }
            span {
              margin-bottom: 10px;
              display: inline-block;
            }
          }
          .bankname {
            .selectBox,
            select {
              width: 90%;
              margin-top: 1em;
              font-size: 14px;
            }
            p {
              font-size: 0.95em;
            }
          }
          .bank {
            &-transfer-info {
              font-size: 15px;
              line-height: 1;
              clear: both;
            }
            &-image-message {
              background: url(/media/images/checkout/bank_logo.gif) no-repeat -30px top;
              width: 100px;
              height: 25px;
              float: left;
              position: relative;
              top: -3px;
            }
          }
        }
        .newsletter-info {
          font-size: 18px;
          .continue-buttons {
            .form-submit {
              line-height: 0;
              float: right;
              margin-top: 25px;
              min-width: 140px;
              height: 40px;
              font-size: 20px;
              font-family: $font--dbheavent;
            }
          }
        }
        .shipping-info,
        .billing-info,
        .giftwrap-info,
        .create-password {
          fieldset {
            max-width: 100%;
            .field-container {
              &--grid {
                .form-item {
                  width: 48%;
                  float: left;
                  margin-#{$ldirection}: 10px;
                  input {
                    width: 100%;
                  }
                }
              }
              .title_container {
                width: 100%;
                font-size: 16px;
                .form-item {
                  margin-left: 25px;
                  width: auto;
                }
              }
            }
          }
        }
        .billing-info {
          #delivery_options {
            font-size: 18px;
          }
          .form-item {
            &.radio {
              font-size: 16px;
            }
          }
        }
      }
    }
    &__panel {
      &-title {
        padding-bottom: 10px;
        margin: 20px 0;
        border-bottom: 1px solid $color-gray-light-apt;
      }
    }
  }
  .checkout-progress-bar {
    &__list-item {
      &--payment_select {
        border-bottom: 1px solid $black;
        a {
          pointer-events: all;
          color: $black;
        }
      }
    }
  }
}

.checkout {
  &__sidebar {
    .customer-service {
      &-content {
        text-align: center;
        font-size: 18px;
      }
      &-header {
        font-size: 30px;
        text-align: center;
        border: none;
        margin: 0;
      }
    }
    .order {
      &-summary {
        &-panel__title {
          font-size: 30px;
          text-align: center;
          border: none;
          margin: 0;
        }
        &__content {
          p {
            text-align: center;
            font-size: 16px;
          }
        }
      }
      &_table {
        width: 100%;
        th {
          width: 65%;
          font-weight: normal;
          font-size: 20px;
        }
      }
    }
    #offer-code-panel {
      .offer-code {
        &__code {
          input {
            width: 55%;
            float: left;
            padding: 0;
            height: 47px;
          }
          .label-content {
            display: none;
          }
        }
        &__one-offer-only {
          clear: both;
        }
      }
      .form-submit {
        width: 35%;
        float: right;
        height: 47px;
      }
    }
    .samples-promo-panel {
      .product-img {
        max-width: 140px;
        float: #{$ldirection};
        width: 50%;
      }
      .details {
        width: 50%;
        float: #{$rdirection};
      }
      .samples-content {
        &__samples {
          &--button-container {
            padding: 0;
          }
          &--button {
            position: static;
          }
        }
        &__form {
          margin: 0 auto;
        }
      }
      .product-list {
        max-height: 445px;
        overflow: auto;
      }
      padding: 1px 0;
      border-bottom: 1px solid $color-light-gray;
    }
  }
  .checkout-progress-bar {
    &__list-item {
      a {
        pointer-events: none;
      }
    }
  }
  &-shipping,
  &-review {
    .checkout__sidebar {
      .offer-code-content {
        &__btn {
          width: 41%;
          padding: 0;
        }
        &__input {
          width: 58%;
          input {
            padding-right: 30px;
          }
        }
      }
    }
  }
}

.address-form {
  .form-item {
    &.full {
      width: 100%;
      clear: left;
    }
  }
  .address-search-results {
    width: 50%;
    float: left;
  }
  .state,
  .postal_code {
    &_container {
      margin-bottom: 0;
    }
  }
}

.review {
  .checkout-progress-bar {
    &__list-item {
      &--review {
        border-bottom: 1px solid $black;
        a {
          pointer-events: all;
          color: $black;
        }
      }
      &--payment_select {
        a {
          pointer-events: all;
        }
      }
    }
  }
}

.shipping_wrapper {
  overflow: hidden;
}
