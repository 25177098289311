.appt-book {
  .form-container .location-select,
  .form-container .location-select option,
  .appt-book-location__signin-text,
  .book-appt-container input,
  .book-appt-container input::placeholder,
  .location-submit,
  .start-over,
  .schedulle-text,
  .your-lessons h4,
  .appointment-info-detail h4,
  .location h4 {
    font-size: 23px !important;
  }
  .location-link .appt-book-link,
  #appt-book-book-submit {
    padding-top: 8px;
    font-size: 23px !important;
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        overflow: visible;
        .registration__email-list-text {
          font-size: 23px !important;
          font-family: 'Neue Haas Unica Pro', 'DB Heavent', sans-serif;
          font-variant: normal;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 18px;
        }
        .registration__email-list {
          height: 180px;
          width: 100%;
          margin-right: 26px;
          max-width: 485px;
          padding: 0 3px;
        }
        .registration__privacy-policy-list {
          height: 180px;
          width: 100%;
          margin: 0;
          max-width: 485px;
          padding: 0 7px;
          float: left;
        }
        .mobile-phone-fieldset {
          .appt-book-mobile-prefix {
            width: 22% !important;
          }
          .appt-book-mobile {
            width: 78% !important;
          }
        }
      }
    }
  }
  .service-select {
    .service {
      .selection-bar {
        .selection-selector {
          font-size: 9px;
          letter-spacing: 1.5px;
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .registration__email-list,
        .registration__privacy-policy-list {
          max-width: 347px !important;
          height: 100px !important;
        }
      }
    }
  }
}

#appointment-cancellation {
  .button-container {
    margin-bottom: 30px;
  }
}
