/**
 * @file my_pc.scss
 *
 * \brief Pull all parts of the US-PC theme together into one file
 */
$password_strength: true;

@import 'overrides/overrides.scss';
@import 'base/fonts.scss';
/*  cremedelamer2_base Base  */
@import '../../cremedelamer2_base/scss/cremedelamer2-base-pc';
/*  LM TH Base - common for both PC and Mobile */
@import 'base/mixins.scss';
/*  Global - common for both PC and Mobile */
@import 'global/all.scss';
/* Checkout */
@import 'pc/sections/checkout_pc.scss';

// One Trust Cookie Styles.
@import '../../cremedelamer2_base/scss/sections/one_trust_cookie/cookie_settings_ot';

/*  Book Appointment */
@import 'pc/sections/appt_booking_pc.scss';

// market specific SCSS
@import 'sections/account/account-reset-password-strength';

// Welcome Overlay
@import 'sections/gwp_pc.scss';
@import 'sections/all';

.site-footer-formatter {
  &__term-link {
    vertical-align: middle;
  }
  &__accessibility {
    a {
      line-height: get-line-height(14px, 35px);
    }
  }
}
